import axiod from "https://deno.land/x/axiod@0.26.2/mod.ts";
import Toastify from "npm:toastify-js";

const BASE_URL = "http://217.138.215.122:3000";

export const SuccessToast = (text: string) => {
    Toastify({
        text: text,
        offset: { y: 40 },
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
            background: "#43a158",
        },
        onClick: function () {},
    }).showToast();
};

export const AlertToast = (text: string) => {
    Toastify({
        text: text,
        offset: { y: 40 },
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
            background: "#17a2b8",
        },
        onClick: function () {},
    }).showToast();
};

export const ErrorToast = (text: string) => {
    Toastify({
        text: text,
        offset: { y: 40 },
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
            background: "#dc3545",
        },
        onClick: function () {},
    }).showToast();
};

export async function encryptData(publicKey: string, data: any) {
    try {
        const response = await axiod.post(`${BASE_URL}/encrypt`, {
            publicKey,
            data,
        });
        const encryptedUserDetails = response.data.encryptedUserDetails;
        const encryptedUserDeviceDetails =
            response.data.encryptedUserDeviceDetails;
        return {
            userDetails: encryptedUserDetails,
            userDeviceDetails: encryptedUserDeviceDetails,
        };
    } catch (error) {
        console.error("Error encrypting data:", error);
    }
}

